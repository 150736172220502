/* .userpage {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.flipbook-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden; 
}

.demoPage {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  background-color: rgb(255, 255, 255);
  /* border: 1px solid black; */
}

.page-info {
  margin-top: 10px; /* Default margin for larger screens */
  font-size: 18px;
  color: #333;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .page-info {
    margin-top: 5px; /* Adjust margin for mobile screens */
  }
}

.go-to-first-page-btn {
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.go-to-first-page-btn:hover {
  background-color: #0056b3;
}

.views-count {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.views-count i {
  margin-right: 5px;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .views-count {
    bottom: 5px;
    right: 5px;
    padding: 3px 5px;
    font-size: 12px;
  }
}